import React, { useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import Input from "components/abstract/input";
import Dropdown from "components/abstract/dropdown";
import { discordRole } from "configlink";
import { useDispatch } from "react-redux";
import { sendContact, setError } from "redux/strapi/action";
import Button from "components/abstract/Button";
function ContactCard(props) {
  const {select} = props
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [services, setServices] = useState(select ? select : "0");
  const [message, setMessage] = useState();

  function sendMessage() {
    if (!firstName || !lastName || !phone || !services || !message) {
      dispatch(setError("Vous n'avez pas remplis tout les champs"));
    } else {
      dispatch(sendContact(firstName, lastName, phone, services, message));
      clearValues();
    }
  }
  function clearValues() {
    setFirstName("");
    setLastName("");
    setPhone("");
    setServices(0);
    setMessage("");
  }
  return (
    <>
      <div className={styles.ContactCardMain}>
        <h3>Contactez-nous </h3>
        <Input
          value={firstName}
          setValue={setFirstName}
          placeHolder={"Prénom"}
          label={"Prénom"}
        />
        <Input
          value={lastName}
          setValue={setLastName}
          placeHolder={"Nom"}
          label={"Nom"}
        />
        <Input
          value={phone}
          setValue={setPhone}
          placeHolder={"5551010"}
          label={"Téléphone"}
        />
        <div className={styles.contactCustomInput}>
          <label className={styles.contactLabel}>
            Selectionnez un services
          </label>
          <select
            onChange={(e) => {
              setServices(e.target.value);
            }}
            value={services}
          >
            <option selected value="0">
              Choisissez un service
            </option>
            {discordRole.map((itm, idx) => {
              return <option value={itm.id}>{itm.name}</option>;
            })}
          </select>
        </div>
        <div className={styles.contactCustomInput}>
          <label className={styles.contactLabel}>Motif de la demande</label>
          <textarea
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
            className={styles.textArea}
          ></textarea>
        </div>
        <Button
          className={styles.contactCardBtn}
          title={"Ok"}
          onClick={sendMessage}
        />
      </div>
    </>
  );
}
export default ContactCard;
