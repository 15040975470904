import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { image_link } from "configlink";
import { FaCircle } from "react-icons/fa";

function EntreprisePersonnel(props) {
  const { entreprise, idx, entrepriseId } = props;
  const navigate = useNavigate();
  if (entreprise) {
    return (
      <>
        <div key={idx} className={styles.postPreviewContainer}>
          <img
            className={styles.postPreviewImage}
            src={`${image_link}${entreprise?.image.data.attributes.url}`}
          />
          <div className={styles.postPreviewText}>
            <p className={styles.postPreviewTitre}>{entreprise.nom}</p>
            <p className={styles.postPreviewDec}>{entreprise.fonction}</p>
            <p className={styles.postPreviewDec}>{entreprise.phone}</p>
          </div>
        </div>
      </>
    );
  }
}
export default EntreprisePersonnel;
