export const strapi = {
  GET_POST: "get_post",
  GET_GOUV_POST: "get_gouv_post",
  GET_SENAT_POST: "get_senat_post",
  GET_SERVICES_POST: "get_services_post",
  GET_JUSTICE_POST: "get_justice_post",
  GET_SELECTED_POST: "GET_SELECTED_POST",
  LOAD: "load",
  ERROR_MESSAGE: "error_message",
  GET_ENTREPRISE: "get_entreprise",
  GET_ENTREPRISE_PERSONNEL: "get_entreprise_personnel",
  GET_SELECTED_ENTREPRISE: "get_selected_entreprise",
};
