import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPosts, getPostsById } from "redux/strapi/action";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styles from "./styles.module.css";
import { useLocation, useParams } from "react-router-dom";
import Header from "components/concrete/Header";
import Footer from "components/concrete/Footer";

function Post(props) {
  const strapi = useSelector((state) => state.strapi);
  const { postId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    console.log(postId)
    if(strapi?.postSelected?.id != postId){
      dispatch(getPostsById(postId));

    }
    if (!strapi.postSelected) {
      dispatch(getPostsById(postId));
    }
  }, [strapi.postSelected]);
  if (!strapi.postSelected) return null;
  else {
    return (
      <>
        <div className={styles.container}>
          <Header />
          <div className={styles.postRichtext}>
            <BlocksRenderer
              content={strapi.postSelected.attributes.core}
              blocks={{
                list: ({ children, format }) => (
                  <ul
                    style={{
                      listStyleType: format === "ordered" ? "decimal" : "disc",
                    }}
                  >
                    {children}
                  </ul>
                ),
                image: ({image}) => <img src={image.url} className={styles.image} />,
                listItem: ({ children }) => <li>{children}</li>,
                link: ({ children, url }) => <a href={url}>{children}</a>,
                heading: ({ children, level }) => {
                  const HeadingTag = `h${level}`;
                  return <HeadingTag>{children}</HeadingTag>;
                },
                paragraph: ({ children }) => <p>{children}</p>,
                text: ({ text, bold }) =>
                  bold ? <strong>{text}</strong> : <>{text}</>,
              }}
            />
          </div>
        </div>
          <Footer / >
      </>
    );
  }
}

export default Post;
