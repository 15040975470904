import React, { useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import Input from "components/abstract/input";
import Dropdown from "components/abstract/dropdown";
import { discordRole } from "configlink";
import { useDispatch } from "react-redux";
import { sendMusicContact, setError } from "redux/strapi/action";
import Button from "components/abstract/Button";
function MusicContactCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [songs, setSongs] = useState();
  const [pseudo, setPseudo] = useState();
  const [iban, setIban] = useState();
  const [message, setMessage] = useState();

  function sendMessage() {
    if (
      !firstName ||
      !lastName ||
      !phone ||
      !songs ||
      !pseudo ||
      !iban ||
      !message
    ) {
      dispatch(setError("Vous n'avez pas remplis tout les champs"));
    } else {
      dispatch(
        sendMusicContact(
          firstName,
          lastName,
          phone,
          pseudo,
          songs,
          iban,
          message
        )
      );
      clearValues();
    }
  }
  function clearValues() {
    setFirstName("");
    setLastName("");
    setPhone("");
    setSongs("");
    setMessage("");
    setIban("");
    setIban("");
  }
  return (
    <>
      <div className={styles.ContactCardMain}>
        <h3>Contactez-nous </h3>
        <Input
          value={firstName}
          setValue={setFirstName}
          placeHolder={"Prénom"}
          label={"Prénom"}
        />
        <Input
          value={lastName}
          setValue={setLastName}
          placeHolder={"Nom"}
          label={"Nom"}
        />
        <Input
          value={phone}
          setValue={setPhone}
          placeHolder={"5551010"}
          label={"Téléphone"}
        />
        <Input
          value={pseudo}
          setValue={setPseudo}
          placeHolder={"Lil Uzi"}
          label={"Nom d'artiste"}
        />
        <Input
          value={iban}
          setValue={setIban}
          placeHolder={"1020"}
          label={"IBAN"}
        />
        <div className={styles.contactCustomInput}>
          <label className={styles.contactLabel}>
            Liste de vos chansons streamés
          </label>
          <textarea
            onChange={(e) => {
              setSongs(e.target.value);
            }}
            value={songs}
            className={styles.textArea}
          ></textarea>
        </div>
        <div className={styles.contactCustomInput}>
          <label className={styles.contactLabel}>Justificatif de stream</label>
          <textarea
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
            className={styles.textArea}
          ></textarea>
        </div>
        <Button
          className={styles.contactCardBtn}
          title={"Ok"}
          onClick={sendMessage}
        />
      </div>
    </>
  );
}
export default MusicContactCard;
