import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
function Footer (){
    const navigate = useNavigate();
    return(
        <>
        <div className={styles.containerHeader}>
            <img src="https://admin.costia.tech/uploads/GOUV_LOGO_b1cbdae204.png" className={styles.footerImage} />
        </div>
        </>
    )
}
export default Footer;