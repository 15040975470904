import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Justice from "screens/Justice";
import Gouv from "screens/gouv";
import Home from "screens/Home";
import Post from "screens/post";
import Senat from "screens/senat";
import ServicesPublic from "screens/servicesPublic";
import Contact from "screens/Contact";
import { useSelector } from "react-redux";
import MessageBox from "components/concrete/MessageBox";
import Entreprise from "screens/Entreprise";
import { TailSpin } from "react-loader-spinner";
import PostEntreprise from "screens/postEntreprise";
import MusicContact from "screens/MusicContact";

function App() {
  const strapi = useSelector((state) => state.strapi);
  return (
    <>
      {strapi.error ? <MessageBox message={strapi.error} /> : null}
      {strapi.isLoading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 9998,
          }}
        >
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#0000FF"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
            wrapperClass=""
          />
        </div>
      ) : null}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/gouvernement" element={<Gouv />} />
          <Route path="/senat" element={<Senat />} />
          <Route path="/servicespublic" element={<ServicesPublic />} />
          <Route path="/justice" element={<Justice />} />
          <Route path="/post/:postId" element={<Post />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/entreprise" element={<Entreprise />} />
          <Route path="/entreprise/:entrepriseId" element={<PostEntreprise />} />
          <Route path="/contactmusic" element={<MusicContact />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
