import React from "react";
import styles from "./button.module.css";

function Button(props) {

	const { leftIcon, title, rightIcon, size, className, onClick, withLink, link, data, id, image } = props;
if(withLink){

	return (
		<div className={styles.container + " " + (styles[size] || "") + " " + (className || "")}>
			{leftIcon ? <img className={styles.left_icon} src={leftIcon} alt="" /> : null}
			<span className={styles.title}>{title}</span>
			{rightIcon ? <img className={styles.right_icon} src={rightIcon} alt="" /> : null}
		</div>
	);
}else{
	return (
		<div className={styles.container + " " + (styles[size] || "") + " " + (className || "")} onClick={onClick}>
		{leftIcon ? <img className={styles.left_icon} src={leftIcon} alt="" /> : null}
		<span className={styles.title}>{title}</span>
		{rightIcon ? <img className={styles.right_icon} src={rightIcon} alt="" /> : null}
	</div>
	)
}
}

export default Button;
