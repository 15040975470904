//r

import axios from "axios";
import { api_link, discordWebHook, musicHook } from "configlink";
import { strapi } from "redux/types";

export const getAllPosts = () => (dispatch) => {
  dispatch(getPosts());
  dispatch(getGouvPosts());
  dispatch(getSenatPosts());
  dispatch(getServicesPosts());
  dispatch(getJusticePosts());
  dispatch(getEntreprise());
  dispatch(getEntreprisePersonnel());
};

export const getPosts = () => (dispatch) => {
  axios
    .get(`${api_link}posts`, {
      params: {
        populate: "*",
        sort: ["createdAt:desc"],
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: strapi.GET_POST,
        payload: res.data.data,
      });
    });
};
export const getPostsById = (id) => (dispatch) => {
  axios
    .get(`${api_link}posts/${id}`, {
      params: {
        populate: "*",
      },
    })
    .then((res) => {
      console.log("getPostsById", res);
      dispatch({
        type: strapi.GET_SELECTED_POST,
        payload: res.data.data,
      });
    });
};
export const getEntrepriseById = (id) => (dispatch) => {
  axios
    .get(`${api_link}entreprises/${id}`, {
      params: {
        populate: "*",
      },
    })
    .then((res) => {
      dispatch({
        type: strapi.GET_SELECTED_ENTREPRISE,
        payload: res.data.data,
      });
    });
};
export const getGouvPosts = () => (dispatch) => {
  axios
    .get(`${api_link}posts`, {
      params: {
        populate: "*",
        sort: "createdAt:desc",
        filters: {
          categorie: {
            categorie: {
              $eq: "Gouvernement",
            },
          },
        },
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: strapi.GET_GOUV_POST,
        payload: res.data.data,
      });
    });
};
export const getSenatPosts = () => (dispatch) => {
  axios
    .get(`${api_link}posts`, {
      params: {
        populate: "*",
        sort: "createdAt:desc",
        filters: {
          categorie: {
            categorie: {
              $eq: "Sénat",
            },
          },
        },
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: strapi.GET_SENAT_POST,
        payload: res.data.data,
      });
    });
};
export const getServicesPosts = () => (dispatch) => {
  axios
    .get(`${api_link}posts`, {
      params: {
        populate: "*",
        sort: "createdAt:desc",
        filters: {
          categorie: {
            categorie: {
              $eq: "Services publics",
            },
          },
        },
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: strapi.GET_SERVICES_POST,
        payload: res.data.data,
      });
    });
};
export const getEntreprise = () => (dispatch) => {
  axios
    .get(`${api_link}entreprises`, {
      params: {
        populate: "*",
        sort: "createdAt:desc",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: strapi.GET_ENTREPRISE,
        payload: res.data.data,
      });
    });
};
export const getEntreprisePersonnel = () => (dispatch) => {
  axios
    .get(`${api_link}personnel-entreprises`, {
      params: {
        populate: "*",
        sort: "createdAt:desc",
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: strapi.GET_ENTREPRISE_PERSONNEL,
        payload: res.data.data,
      });
    });
};

export const getJusticePosts = () => (dispatch) => {
  axios
    .get(`${api_link}posts`, {
      params: {
        populate: "*",
        sort: "createdAt:desc",
        filters: {
          categorie: {
            categorie: {
              $eq: "Justice",
            },
          },
        },
      },
    })
    .then((res) => {
      console.log(res);
      dispatch({
        type: strapi.GET_JUSTICE_POST,
        payload: res.data.data,
      });
    });
};
export const setloading = (value) => (dispatch) => {
  dispatch({
    type: strapi.LOAD,
    payload: value,
  });
};
export const setError = (value) => (dispatch) => {
  dispatch({
    type: strapi.ERROR_MESSAGE,
    payload: value,
  });
};
export const resetMessage = () => (dispatch) => {
  dispatch({
    type: strapi.ERROR_MESSAGE,
    payload: null,
  });
};

export const sendContact =
  (firstName, lastName, number, secretariat, message) => (dispatch) => {
    let newEmbed = {
      author: {
        name: "Contact site web",
        icon_url: "https://admin.costia.tech/uploads/GOUV_LOGO_b1cbdae204.png",
      },
      title: "Rendez-vous GOUV",
      fields: [
        {
          name: "Services",
          value: `<@&${secretariat}>`,
        },
        {
          name: "Prénom et Nom",
          value: `${firstName} ${lastName}`,
          inline: true,
        },
        {
          name: "Téléphone",
          value: `${number}`,
          inline: true,
        },
        {
          name: "Message",
          value: `${message}`,
        },
      ],
      footer: {
        text: "Gouvernement",
        icon_url: "https://admin.costia.tech/uploads/GOUV_LOGO_b1cbdae204.png",
      },
      timestamp: new Date().toISOString(),
    };

    axios
      .post(discordWebHook, {
        content: `<@&${secretariat}>`,
        embeds: [newEmbed],
      })
      .then(() => {
        dispatch({
          type: strapi.ERROR_MESSAGE,
          payload: "Message envoyé avec succès",
        });
      })
      .catch((error) => {
        dispatch({
          type: strapi.ERROR_MESSAGE,
          payload: "Erreur lors de l'envoi du message",
        });
        console.error("Erreur lors de l'envoi du webhook Discord:", error);
      });
  };

export const sendMusicContact =
  (firstName, lastName, number, pseudo, songs, iban, message) => (dispatch) => {
    let newEmbed = {
      author: {
        name: "Contact site web",
        icon_url:
          "https://admin.costia.tech/uploads/BMI_Logo_blue_spark_svg_ba23e12985.png",
      },
      title: "Rendez-vous GOUV",
      fields: [
        {
          name: "Prénom et Nom",
          value: `${firstName} ${lastName}`,
          inline: true,
        },
        {
          name: "Nom d'artiste",
          value: `${pseudo}`,
          inline: true,
        },
        {
          name: "Téléphone",
          value: `${number}`,
          inline: true,
        },
        {
          name: "IBAN",
          value: `${iban}`,
          inline: true,
        },
        {
          name: "Message",
          value: `${message}`,
        },
        {
          name: "Liste des music",
          value: `${songs}`,
        },
      ],
      footer: {
        text: "Gouvernement",
        icon_url:
          "https://admin.costia.tech/uploads/BMI_Logo_blue_spark_svg_ba23e12985.png",
      },
      timestamp: new Date().toISOString(),
    };

    axios
      .post(musicHook, {
        content: "<@439797467945369622>",
        embeds: [newEmbed],
      })
      .then(() => {
        dispatch({
          type: strapi.ERROR_MESSAGE,
          payload: "Message envoyé avec succès",
        });
      })
      .catch((error) => {
        dispatch({
          type: strapi.ERROR_MESSAGE,
          payload: "Erreur lors de l'envoi du message",
        });
        console.error("Erreur lors de l'envoi du webhook Discord:", error);
      });
  };
