import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosts, getPosts } from "redux/strapi/action";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styles from "./styles.module.css";
import Header from "components/concrete/Header";
import Footer from "components/concrete/Footer";
import Card from "components/concrete/card";

function Justice(props) {
  const strapi = useSelector((state) => state.strapi);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!strapi.posts) {
      dispatch(getAllPosts());
    }
  }, [strapi]);
  return (
    <>
      <div className={styles.container}>
        <Header />
        <Card
          title={"Justice"}
          desc={"Les dernières actualités de la justice"}
          link={"gouvernement"}
          post={strapi?.postsJustice}
          lisBig={true}
        />
      </div>
            <Footer / >
    </>
  );
}

export default Justice;
