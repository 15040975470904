import { strapi } from "redux/types";

const initialState = {
  error: null,
  isLoading: false,
  posts: null,
  postsGouv: null,
  postsSenat: null,
  postsServices: null,
  postsJustice: null,
  postSelected: null,
  entreprise: null,
  entrepriseSelected: null,
  entreprisePersonnel: null,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case strapi.GET_POST:
      return {
        ...state,
        posts: action.payload,
      };
    case strapi.GET_GOUV_POST:
      return {
        ...state,
        postsGouv: action.payload,
      };
    case strapi.GET_SENAT_POST:
      return {
        ...state,
        postsSenat: action.payload,
      };
    case strapi.GET_SERVICES_POST:
      return {
        ...state,
        postsServices: action.payload,
      };
    case strapi.GET_JUSTICE_POST:
      return {
        ...state,
        postsJustice: action.payload,
      };
    case strapi.GET_SELECTED_POST:
      return {
        ...state,
        postSelected: action.payload,
      };
    case strapi.GET_SELECTED_ENTREPRISE:
      return {
        ...state,
        entrepriseSelected: action.payload,
      };
    case strapi.LOAD:
      return {
        ...state,
        isLoading: action.payload,
      };
    case strapi.ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
      };
    case strapi.GET_ENTREPRISE:
      return {
        ...state,
        entreprise: action.payload,
      };
    case strapi.GET_ENTREPRISE_PERSONNEL:
      return {
        ...state,
        entreprisePersonnel: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
