import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEntrepriseById,
  getEntreprisePersonnel,
  getPosts,
  getPostsById,
} from "redux/strapi/action";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styles from "./styles.module.css";
import { useLocation, useParams } from "react-router-dom";
import Header from "components/concrete/Header";
import Footer from "components/concrete/Footer";
import EntreprisePersonnel from "components/abstract/entreprisePersonnel";
import EntrepriseBanner from "components/abstract/entrepriseBanner";
import ContactCard from "components/concrete/ContactCard";

function PostEntreprise(props) {
  const strapi = useSelector((state) => state.strapi);
  const { entrepriseId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [toLoad, setLoad] = useState(false);
  useEffect(() => {
    if (!toLoad) {
      dispatch(getEntrepriseById(entrepriseId));
      dispatch(getEntreprisePersonnel());
      setLoad(true);
    }
  }, [toLoad]);
  if (!strapi.entrepriseSelected && !strapi.entreprisePersonnel) return null;
  else {
    return (
      <>
        <div className={styles.container}>
          <Header />
          <div className={styles.postRichtext}>
            {strapi.entrepriseSelected ? (
              <>
                <EntrepriseBanner
                  entreprise={strapi?.entrepriseSelected.attributes}
                />
                <BlocksRenderer
                  content={strapi?.entrepriseSelected?.attributes?.info}
                  blocks={{
                    list: ({ children, format }) => (
                      <ul
                        style={{
                          listStyleType:
                            format === "ordered" ? "decimal" : "disc",
                        }}
                      >
                        {children}
                      </ul>
                    ),
                    image: ({ image }) => (
                      <img src={image.url} className={styles.image} />
                    ),
                    listItem: ({ children }) => <li>{children}</li>,
                    link: ({ children, url }) => <a href={url}>{children}</a>,
                    heading: ({ children, level }) => {
                      const HeadingTag = `h${level}`;
                      return <HeadingTag>{children}</HeadingTag>;
                    },
                    paragraph: ({ children }) => <p>{children}</p>,
                    text: ({ text, bold }) =>
                      bold ? <strong>{text}</strong> : <>{text}</>,
                  }}
                />
              </>
            ) : null}
             {strapi.entreprisePersonnel
              ? strapi.entreprisePersonnel.map((item, idx) => {
                  console.log(
                    item
                  );
                  if (
                    item.attributes.entreprise?.data?.id ===
                    strapi.entrepriseSelected.id
                  ) {
                    return (
                      <EntreprisePersonnel
                        entreprise={item.attributes}
                        idx={idx}
                        entrepriseId={item.id}
                      />
                    );
                  }
                })
              : null} 
            {strapi.entrepriseSelected.attributes.status ? (
              <div className={styles.contactEntreprise}>
                <div className={styles.contactEntrepriseText}>
                  <h4>Cette entreprise vous intéresse ?</h4>
                  <p>
                    Pour en savoir plus sur cette entreprise ou pour exprimer
                    votre intérêt, veuillez utiliser le formulaire de contact
                    ci-dessous. Assurez-vous de sélectionner "Secrétariat aux
                    entreprises" comme destinataire pour que votre message soit
                    traité rapidement et efficacement.
                  </p>
                </div>
                <ContactCard select={"1280254102659665973"} />
              </div>
            ) : null}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PostEntreprise;
