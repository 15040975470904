import React from "react";
import { useDispatch } from "react-redux";

//Style
import styles from "./Message_Box.module.css";

//JSX
import Button from "components/abstract/Button";
import Heading from "components/abstract/Text/Heading";
import { resetMessage } from "redux/strapi/action";

function MessageBox(props) {
  const { message } = props;

  const dispatch = useDispatch();

  function reset() {
    dispatch(resetMessage());
  }
  return (
    <>
      <div className={styles.Message_Box_Main}>
        <div className={styles.Message_Box_Header_And_Text}>
          <Heading title={"Message"} />
          <p className={styles.colorMessage}>{message}</p>
        </div>
        <div className={styles.Message_Box_button}>
          <Button
            className={styles.Message_Box_Btn}
            size={"small"}
            title={"Ok"}
            onClick={reset}
          />
        </div>
      </div>
    </>
  );
}
export default MessageBox;
