import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import PostPreview from "components/abstract/postPreview";
import { IoMdArrowForward } from "react-icons/io";

function Card(props) {
  const navigate = useNavigate();
  const { post, title, desc, isBig } = props;
  return (
    <>
      {isBig ? (
        <div className={styles.containerCard}>
        <div className={styles.cardHeader}>
          <div className={styles.cardHeaderHeader}>
            <p className={styles.cardHearderText}>{title}</p> 
          </div>
          <p className={styles.cardHearderDesc}>{desc}</p>
        </div>
        <div className={styles.cardContent}>
          {post
            ? post.map((item, idx) => {
                return (
                  <PostPreview
                    key={idx}
                    post={item.attributes}
                    idx={idx}
                    postId={item.id}
                  />
                );
              })
            : null}
        </div>
      </div>
      ) : (
        <div className={styles.containerCard}>
          <div className={styles.cardHeader}>
            <div className={styles.cardHeaderHeader}>
              <p className={styles.cardHearderText}>{title}</p>
            </div>
            <p className={styles.cardHearderDesc}>{desc}</p>
          </div>
          <div className={styles.cardContent}>
            {post
              ? post.map((item, idx) => {
                  if (idx > 5) return;
                  return (
                    <PostPreview
                      key={idx}
                      post={item.attributes}
                      idx={idx}
                      postId={item.id}
                    />
                  );
                })
              : null}
          </div>
        </div>
      )}
    </>
  );
}
export default Card;
