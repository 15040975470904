/* 
export const api_link = "api.exemple.com";
*/

export const api_link = "https://admin.costia.tech/api/";
export const image_link = "https://admin.costia.tech";

export const discordWebHook =
  "https://discord.com/api/webhooks/1282810059117826059/PUyHTCsAth2pbkVmNJcDSj8d16_K4s-H20A5QqAMencVcvZq9EbsXyE4EJTU8sD6yCHl";
export const musicHook =
  "https://discord.com/api/webhooks/1285967395386822774/fcalYnbrU0RIaYBDrN5J_v0O3rQgWWZ7qacEHMBb6v0nW1v7knDa7kVBX3HZQh-qmuGe";


export const discordRole = [
  {
    name: "State secretariat of Homeland Security",
    id: "1280253253292130386",
  },
  {
    name: "State secretariat of Public Services",
    id: "1280253327845625867",
  },
  {
    name: "State secretariat of Treasury & Finances",
    id: "1283106984413106267",
  },
  {
    name: "State secretariat of Companies",
    id: "1280254102659665973",
  },
  {
    name: "State secretariat of Rurality & Heritage",
    id: "1282808237271421041",
  },
  {
    name: "State secretariat of Public Relations",
    id: "1283106406274306110",
  },
  {
    name: "State secretariat of Civic & Cultural Life",
    id: "1280253819653193748",
  },
  {
    name: "State secretariat of Transportation",
    id: "1280253327694888960",
  },
  {
    name: " President of Senate",
    id: "1280254828827639830",
  },
  {
    name: "Governor's Office",
    id: "1283107632059777114",
  },
  {
    name: "Lieutenant Governor's Office",
    id: "1283107871558471760",
  },
];
