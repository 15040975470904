import React from "react";
import styles from "./dropdown.module.css";

//Images
import chevron from "static/img/icons/chevron.png";

function Dropdown(props) {
	const { name, className, items } = props;

	return (
		<div className={styles.container}>
			<div className={styles.button + " " + (className || "")}>
				<span className={styles.name}>{name}</span>
				<img className={styles.chevron} src={chevron} alt="" />
			</div>
			<div className={styles.items}>
				{items.map((item, index) => (
					<div key={index} onClick={item.onClick}>
						<span>{item.name}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default Dropdown;