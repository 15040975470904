import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { FaBars } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const location = useLocation(); // Obtenir l'URL actuelle

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const getPageName = () => {
    console.log(location.pathname)
    switch (location.pathname) {
      case "/":
        return "Home";
      case "/gouvernement":
        return "Gouvernement";
      case "/senat":
        return "Sénat";
      case "/servicespublic":
        return "Services publics";
      case "/justice":
        return "Justice";
      case "/contact":
        return "Contact";
      case "/contactmusic":
        return "Contact Music";
      case "/entreprise":
        return "Entreprise";
      default:
        return "Post";
    }
  };
  const isMobile = width <= 768;
  return (
    <>
      <div className={styles.containerHeader}>
        {!isMobile ? (
          <>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/gouvernement");
              }}
            >
              Gouvernement
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/senat");
              }}
            >
              Sénat
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/servicespublic");
              }}
            >
              Services publics
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/justice");
              }}
            >
              Justice
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/entreprise");
              }}
            >
              Entreprise
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                navigate("/contactmusic");
              }}
            >
              Contact Music
            </button>
          </>
        ) : (
          <>
            <div className={styles.headerContainer}>
              {/* Bouton pour l'accordéon en mobile */}
              <div className={styles.headerMobileDiv}>
                <p className={styles.pageName}>{getPageName()}</p>
                <button
                  className={styles.accordionButton}
                  onClick={toggleAccordion}
                >
                  <FaBars color="white" />
                </button>
              </div>

              {/* Menu qui s'affiche si isOpen est true (mobile) ou toujours visible (desktop) */}
              <div
                className={`${styles.menuItems} ${isOpen ? styles.open : ""}`}
              >
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/");
                    setIsOpen(false); // Fermer l'accordéon après navigation
                  }}
                >
                  Home
                </button>
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/gouvernement");
                    setIsOpen(false);
                  }}
                >
                  Gouvernement
                </button>
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/senat");
                    setIsOpen(false);
                  }}
                >
                  Sénat
                </button>
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/servicespublic");
                    setIsOpen(false);
                  }}
                >
                  Services publics
                </button>
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/justice");
                    setIsOpen(false);
                  }}
                >
                  Justice
                </button>
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/entreprise");
                  }}
                >
                  Entreprise
                </button>
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/contact");
                    setIsOpen(false);
                  }}
                >
                  Contact
                </button>
                <button
                  className={styles.headerButton}
                  onClick={() => {
                    navigate("/contactmusic");
                    setIsOpen(false);
                  }}
                >
                  Contact Music
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default Header;
