import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import Header from "components/concrete/Header";
import Footer from "components/concrete/Footer";
import ContactCard from "components/concrete/ContactCard";
import EntreprisePreview from "components/abstract/entreprisePreview";
import { getEntreprise, getEntreprisePersonnel } from "redux/strapi/action";
import Heading from "components/abstract/Text/Heading";

function Entreprise(props) {
  const strapi = useSelector((state) => state.strapi);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!strapi.entreprise || !strapi.entreprisePersonnel) {
      dispatch(getEntreprise());
      dispatch(getEntreprisePersonnel());
    }
  }, [strapi.entreprise, strapi.entreprisePersonnel]);
  if (strapi.entreprise && strapi.entreprisePersonnel) {
    return (
      <>
        <div className={styles.container}>
          <Header />
          <div className={styles.entrepriseCore}>
            <Heading title={"Entreprise disponible a la reprise"} style={styles.bigColors} />
            {strapi.entreprise
              ? strapi.entreprise.map((item, index) => {
                  if (item.attributes.status === false) return;
                  return (
                    <EntreprisePreview
                      entreprise={item.attributes}
                      idx={index}
                      entrepriseId={item.id}
                    />
                  );
                })
              : null}
      {/*              <Heading title={"Entreprise non disponible a la reprise"} style={styles.bigColors} />
            {strapi.entreprise
              ? strapi.entreprise.map((item, index) => {
                  if (item.attributes.status === true) return;
                  return (
                    <EntreprisePreview
                      entreprise={item.attributes}
                      idx={index}
                      entrepriseId={item.id}
                    />
                  );
                })
              : null} */}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Entreprise;
