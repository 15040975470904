import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styles from "./styles.module.css";
import Header from "components/concrete/Header";
import Footer from "components/concrete/Footer";
import Card from "components/concrete/card";
import { getAllPosts } from "redux/strapi/action";

function ServicesPublic(props) {
  const strapi = useSelector((state) => state.strapi);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!strapi.posts) {
      dispatch(getAllPosts());
    }
  }, [strapi]);
  return (
    <>
      <div className={styles.container}>
        <Header />
        <Card
          title={"Services Public"}
          desc={"Les dernières actualités du Services Public"}
          post={strapi?.postsServices}
          lisBig={true}
        />
      </div>
            <Footer / >
    </>
  );
}

export default ServicesPublic;
