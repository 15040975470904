import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import Header from "components/concrete/Header";
import Footer from "components/concrete/Footer";
import MusicContactCard from "components/concrete/MusicContactCard";

function MusicContact(props) {
  const strapi = useSelector((state) => state.strapi);

  return (
    <>
      <div className={styles.container}>
        <Header />
        <MusicContactCard />
      </div>
      <Footer />
    </>
  );
}

export default MusicContact;
