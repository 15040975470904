import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { image_link } from "configlink";
import { FaCircle } from "react-icons/fa";

function EntreprisePreview(props) {
  const { entreprise, idx, entrepriseId } = props;
  const navigate = useNavigate();
  if (entreprise) {
    return (
      <>
        <div
          key={idx}
          className={styles.postPreviewContainer}
          onClick={() => {
            navigate("/entreprise/" + entrepriseId);
          }}
        >
          <img
            className={styles.postPreviewImage}
            src={`${image_link}${entreprise?.logo.data.attributes.url}`}
          />
          <div className={styles.postPreviewText}>
            <p className={styles.postPreviewTitre}>{entreprise.nom}</p>
            <p className={styles.postPreviewDec}>{entreprise.desc}</p>
          </div>
          <div className={styles.entrepriseStatus} >
          <FaCircle color={entreprise.status ? "Green" : "red"} style={{marginRight: "10px"}} size={20} />
          <p className={styles.DispoTXT}>
            {entreprise.status ? "Disponible" : "Non disponible"}
          </p>
          </div>
        </div>
      </>
    );
  }
}
export default EntreprisePreview;
