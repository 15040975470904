import React from "react";
import styles from "./heading.module.css";

//Images
import lines from "static/img/lines.png";

function Heading(props) {
	return (
		<div className={styles.heading}>
			<h2 className={styles.colorMessage + " " + (props.style ? props.style : null)}>{props.title}</h2>
			{!props.hideLine ? <img className={styles.line} src={lines} alt="" /> : null}
		</div>
	);
}

export default Heading;
