import React from "react";

import styles from "./styles.module.css";
import { image_link } from "configlink";
import { FaCircle } from "react-icons/fa";

export default function EntrepriseBanner(props) {
  const { entreprise } = props;
  return (
    <>
      <div className={styles.postPreviewContainer}>
        <img
          className={styles.postPreviewImage}
          src={`${image_link}${entreprise?.logo.data.attributes.url}`}
        />
        <div className={styles.postPreviewText}>
          <p className={styles.postPreviewTitre}>{entreprise.nom}</p>
          <p className={styles.postPreviewDec}>{entreprise.desc}</p>
        </div>
        <div className={styles.entrepriseStatus}>
          <FaCircle
            color={entreprise.status ? "Green" : "red"}
            style={{ marginRight: "10px" }}
            size={20}
          />
          <p className={styles.postDispo}>
            {entreprise.status ? "Disponible" : "Non disponible"}
          </p>
        </div>
      </div>
    </>
  );
}
