import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosts } from "redux/strapi/action";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import styles from "./styles.module.css";
import Header from "components/concrete/Header";
import Footer from "components/concrete/Footer";
import Card from "components/concrete/card";


function Home(props) {
  const strapi = useSelector((state) => state.strapi);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!strapi.posts) {
      dispatch(getAllPosts());
    }
  }, [strapi]);
  return (
    <>
      <div className={styles.container}>
        <Header />
        <Card
          title={"Gouvernement"}
          desc={"Les dernières actualités du gouvernement"}
          post={strapi?.postsGouv}
          lisBig={false}
        />
        <Card
          title={"Sénat"}
          desc={"Les dernières actualités du Sénat"}
          post={strapi?.postsSenat}
          lisBig={false}
        />
        <Card
          title={"Services Public"}
          desc={"Les dernières actualités du Services Public"}
          post={strapi?.postsServices}
          lisBig={false}
        />
        <Card
          title={"Justice"}
          desc={"Les dernières actualités du Justice"}
          post={strapi?.postsJustice}
          lisBig={false}
        />
      </div>
        <Footer / >
    </>
  );
}

export default Home;
