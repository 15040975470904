import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
function Input(props) {
  const { value, setValue, placeHolder, label } = props;
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.InputBox}>
        <label className={styles.InputLabel}>{label}</label>
        <input
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          className={styles.input}
          placeholder={placeHolder}
        />
      </div>
    </>
  );
}
export default Input;
