import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import { image_link } from "configlink";
import { IoMdArrowForward } from "react-icons/io";
function PostPreview(props) {
  const { post, idx, postId } = props;
  const navigate = useNavigate();
  if (post) {
    return (
      <>
        <div
          key={idx}
          className={styles.postPreviewContainer}
          onClick={() => {
            navigate("/post/" + postId);
          }}
        >
          <img
            className={styles.postPreviewImage}
            src={`${image_link}${post?.image.data.attributes.url}`}
          />
          <div className={styles.postPreviewText}>
            <p className={styles.postPreviewTitre}>{post.titre}</p>
            <p className={styles.postPreviewDec}>{post.shortText}</p>
          </div>
          <IoMdArrowForward color={"white"} size={20} />
        </div>
      </>
    );
  }
}
export default PostPreview;
